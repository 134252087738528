<template>
  <ValidationObserver ref="form" v-slot="{ invalid }">
    <div class="space-y-8 divide-y divide-gray-200 bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div class="space-y-6 sm:space-y-5">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">Company Profile</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Please provide accurate information</p>
          </div>

          <div class="space-y-6 sm:space-y-5">
            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="companyName" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Company
                  name</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <div class="flex max-w-lg relative">
                  <input v-model='payload.companyName' type="text" name="companyName" id="companyName"
                    :disabled="payload.tbsCompanyId"
                    class="block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-200">
                  <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="street-address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  <span v-if='!payload.tbsCompanyId'>Street address</span>
                  <span v-else>Address / Zip code &amp; city</span>
                </label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <div class="flex max-w-lg relative">
                  <input v-model='payload.streetAddress' type="text" name="street-address" id="street-address"
                    :disabled="payload.tbsCompanyId"
                    class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm  disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                  <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required" v-if='!payload.tbsCompanyId'
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">City</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <div class="flex max-w-lg  sm:max-w-xs relative">
                  <input v-model='payload.city' type="text" name="city" id="city" :disabled="payload.tbsCompanyId"
                    class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
                  <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required" v-if='!payload.tbsCompanyId'
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="postal-code" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">ZIP / Postal
                  code</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <div class="flex max-w-lg  sm:max-w-xs relative">
                  <input v-model='payload.postalCode' type="text" name="postal-code" id="postal-code"
                    :disabled="payload.tbsCompanyId"
                    class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm">
                  <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Country</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <select v-model='payload.country' name="country" :disabled="payload.tbsCompanyId"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm  disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                  <option v-for="item in countriesOptionsPleaseSelect" :value="item.value" :key='item.value'>{{ item.name
                  }}
                  </option>
                </select>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="website" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Company
                  Website</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="flex max-w-lg  sm:max-w-xs relative">
                <input v-model='payload.website' type="text" name="website" id="website" :disabled="payload.tbsCompanyId"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="tel" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Telephone number</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="flex max-w-lg  sm:max-w-xs relative">
                <input v-model='payload.tel' type="text" name="tel" id="tel" :disabled="payload.tbsCompanyId"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="vat" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Vat number</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="flex max-w-lg  sm:max-w-xs relative">
                <input v-model='payload.vat' type="text" name="vat" id="vat" :disabled="payload.tbsCompanyId"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                <div v-if='errors.length' class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="companyName" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Company
                  profile</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <div class="flex max-w-lg relative">
                  <textarea v-model='payload.profile' id="about" name="about" rows="5" :disabled="payload.tbsCompanyId"
                    class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20"></textarea>
                </div>
                <p class="mt-2 text-sm text-gray-500">Write a few sentences about your company.</p>
              </div>
            </ValidationProvider>


            <ValidationProvider v-slot="{ errors }" rules="required"
              class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div>
                <label for="heardThrough" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">How did you
                  hear
                  from us?</label>
                <p v-if='errors.length' class="mt-2 text-sm text-red-600">{{ errors[0] }}</p>
              </div>
              <div class="mt-1 sm:col-span-2 sm:mt-0">
                <select v-model='payload.heardThrough' name="heardThrough"
                  class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm  disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20">
                  <option v-for="item in heardThroughOptions" :value="item.value" :key='item.value'>{{ item.name
                  }}
                  </option>
                </select>
              </div>
            </ValidationProvider>

          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="flex justify-end">
        <button @click='prev' type="button"
          class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Back
          to start</button>
        <button @click='next' type="button" :disabled="invalid"
          class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Continue
          to Preferences</button>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapState } from 'vuex';

export default {
  name: "RegisterStep2",
  props: {
    companyProfile: Object,
  },
  data() {
    return {
      payload: null,
      heardThroughOptions: [
        { value: "Google or other search engine", name: "Google or other search engine" },
        { value: "Facebook", name: "Facebook" },
        { value: "LinkedIn", name: "LinkedIn" },
        { value: "Business Relation", name: "Business Relation" },
        { value: "TBS Mailing", name: "TBS Mailing" },
        { value: "TBS Sales", name: "TBS Sales" },
        { value: "TBS Website", name: "TBS Website" },
      ]
    };
  },

  computed: {
    ...mapGetters(['countriesOptionsPleaseSelect']),
  },
  created() {
    this.payload = _.cloneDeep(this.companyProfile)
    this.payload.heardThrough = this.payload.heardThrough || this.heardThroughOptions[0].value
  },
  isValid() {
    return this.attendeesLocal.length
  },
  methods: {
    async next() {
      const isValid = await this.$refs.form.validate();
      // if there is no company ID, this is a new registration, so we need to combine the address with the zip code and the country
      if (!this.payload.tbsCompanyId) {
        this.payload.streetAddress = `${this.payload.streetAddress} ${this.payload.city} ${this.payload.postalCode}`
      }
      // console.log(this.payload)
      if (!isValid) return;
      this.$parent.$parent.toStep({ step: 3, companyProfile: this.payload })
    },
    async prev() {
      this.$parent.$parent.toStep({ step: 1, companyProfile: this.payload })
    },
  },
};
</script>
