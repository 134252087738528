<template>
  <base-layout :do-skip-header="false" :doSkipPic="shouldSkipPic">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold mt-8'>
        Scheduler
      </h1>
      <div v-if="isLoaded" class="mt-4">
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a day</label>
          <select v-model="currentTab"
            class="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500">
            <!-- <option :key="0" :value="0">{{ formatDay(days[0]) }}</option>
            <option :key="1" :value="1">{{ formatDay(days[1]) }}</option> -->
            <option :key="0" :value="0">Thursday Oct 10</option>
            <option :key="1" :value="1">Friday Oct 11</option>
            <option :key="2" :value="2">Cancelled Meetings</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <div class="border-b border-gray-200">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- <button :class="tabClass('0')" @click="currentTab = '0'">{{ formatDay(days[0]) }}</button>
              <button :class="tabClass('1')" @click="currentTab = '1'">{{ formatDay(days[1]) }}</button> -->
              <button :class="tabClass('0')" @click="currentTab = '0'">Thursday Oct 10</button>
              <button :class="tabClass('1')" @click="currentTab = '1'">Friday Oct 11</button>
              <button :class="tabClass('2')" @click="currentTab = '2'">Cancelled Meetings</button>
            </nav>
          </div>
        </div>
        <div v-if="currentTab === '0' || currentTab === '1'">
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 mt-8">
            <div v-for="timeslot in currentTimeslots" :key="timeslot.id"
              class="relative items-center space-x-3 rounded-lg shadow-sm focus-within:ring-2 focus-within:ring-red-500 focus-within:ring-offset-2 border"
              :class="timeslot.cssContainer">
              <div>
                <a href="javascript:void(0)" class="focus:outline-none text-sm">
                  <div class="schedule-header text-white px-4 py-1.5 rounded-t-lg" :class="timeslot.cssHeading">
                    {{ timeslot.timeStart }} - {{ timeslot.timeEnd }}
                    <div class="float-right" v-if="timeslot.table">{{ timeslot.table }}</div>
                  </div>
                  <div class="px-4 py-5 ">
                    <div class="mb-4">
                      <div v-if="timeslot.state === 'pending' || timeslot.state === 'accepted'"
                        class="relative flex items-center">
                        <div class="min-w-0 flex-1">
                          <span v-if="timeslot.state === 'pending' && timeslot.meet.isMeFrom">
                            Your request is <span class='font-bold'>PENDING</span> with:
                          </span>
                          <span v-else-if="timeslot.state === 'pending' && !timeslot.meet.isMeFrom">
                            You have a <span class='font-bold'>PENDING</span> request from:
                          </span>
                          <span v-else>
                            You have a <span class='font-bold'>CONFIRMED</span> meeting with:
                          </span>
                          <br />
                          <span class='font-bold'>{{ timeslot.meet.contact.name }}</span>
                          <br />
                          {{ timeslot.meet.contact.companyName }}
                          <br />
                          Email:
                          <span v-if="!timeslot.meet.contact.email">N/A</span>
                          <a v-else :href="timeslot.meet.contact.mailLink" class="text-red-600">{{
                            timeslot.meet.contact.email
                          }}</a>
                          <br />
                          Mobile:
                          <span v-if="!timeslot.meet.contact.tel">N/A</span>
                          <a v-else :href="timeslot.meet.contact.telLink" class="text-red-600">{{
                            timeslot.meet.contact.tel
                          }}</a>
                        </div>
                        <div class="flex-shrink-0">
                          <div v-if="timeslot.meet.contact.isCheckedIn"
                            class="mb-2 px-3 py-1 inline-flex text-xs items-center font-bold leading-sm uppercase bg-green-500 text-white rounded-full">
                            Checked In
                          </div>
                          <div v-else
                            class="mb-2 px-3 py-1 inline-flex text-xs items-center font-bold leading-sm uppercase bg-red-500 text-white rounded-full">
                            Not checked in
                          </div>
                          <div><img class="mx-auto h-16 w-16 rounded-full" :src="timeslot.meet.contact.profilePic">
                          </div>
                        </div>
                      </div>
                      <div v-else-if="timeslot.state === 'fullyBooked'"
                        class="mt-4 text-yellow-800 text-xl text-center font-semibold">
                        No more tables available <br />
                        Please try another time slot
                      </div>
                      <div v-else-if="timeslot.state === 'blocked'"
                        class="mt-4 text-yellow-800 text-xl text-center font-semibold">
                        You blocked this timeslot
                      </div>
                      <div v-else-if="timeslot.state === 'available' || timeslot.state === 'tablesBooked'">
                        <div v-if="timeslot.state === 'tablesBooked'" class="italic text-gray-400 mb-4">
                          No more tables available for this time slot. Please meet in the hotel lobby once your meeting
                          is confirmed.
                        </div>
                      </div>
                    </div>

                    <div v-if="timeslot.keynote" v-html="timeslot.keynote" class="text-red-600 italic text-center"></div>

                    <div class="p-2">&nbsp;</div>
                    <div v-if="schedulerEnabled" class="absolute right-3 bottom-0 py-3 clearfix">
                      <div v-if="timeslot.state === 'pending' && timeslot.meet.isMeFrom">
                        <button type="button" class="button" @click="confirmSendReminder(timeslot)">Send Reminder</button>
                        <button type="button" class="button" @click="confirmDeleteRequest(timeslot, 'cancel')">Cancel Request</button>
                      </div>
                      <div v-else-if="timeslot.state === 'pending' && !timeslot.meet.isMeFrom">
                        <button type="button" class="button" @click="confirmAcceptRequest(timeslot)">Accept Request</button>
                        <button type="button" class="button" @click="confirmDeleteRequest(timeslot, 'decline')">Decline Request</button>
                      </div>
                      <div v-else-if="timeslot.state === 'blocked'">
                        <button type="button" class="button" @click="toggleTimeslotBlock(timeslot.id, 'unblock')">Unblock Timeslot</button>
                      </div>
                      <div v-else-if="timeslot.state === 'accepted'">
                        <button type="button" class="button" @click="confirmDeleteMeeting(timeslot)">Cancel Meeting</button>
                      </div>
                      <div v-else-if="timeslot.state === 'available' || timeslot.state === 'tablesBooked'">
                        <button type="button" class="button" @click="createMeetingRequest(timeslot)">Request Meeting</button>
                        <button type="button" class="button" @click="toggleTimeslotBlock(timeslot.id, 'block')">Block Timeslot</button>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div v-else>

          <!-- for normal displays //-->
          <div class="mt-6 flex flex-col hidden lg:block">
            <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div v-if="meetsCancelled.length>0" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th class="table-cell-header pl-2">Meeting with</th>
                      <th class="table-cell-header">Company name</th>
                      <th class="table-cell-header">Mobile</th>
                      <th class="table-cell-header">Scheduled for</th>
                      <th class="table-cell-header">Cancelled on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in meetsCancelled" :key="index" class="bg-white hover:bg-gray-100 transition duration-150 ease-in-out">
                      <td class="table-cell text-gray-900 pr-0 pl-2"><a :href="`/attendeeDetails/${item.contact.companyId}`" target="new" rel="noopener" class="text-red-600 hover:text-red-400">{{ item.contact.name }}</a></td>
                      <td class="table-cell text-gray-900"><a :href="`/attendeeDetails/${item.contact.companyId}`" target="new" rel="noopener" class="text-red-600 hover:text-red-400">{{ item.contact.companyName }}</a></td>
                      <td class="table-cell text-gray-900"><a :href="`tel:${item.contact.tel}`" class="text-red-600 hover:text-red-400">{{ item.contact.tel }}</a></td>
                      <td class="table-cell text-gray-500">{{ getMeetingDayDetails(item.timeslotId) }}</td>
                      <td class="table-cell text-gray-900">{{ formatDate(item.dtCancelled) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                Any confirmed meetings that have been cancelled will be visible here.<br />
                You currently do not have any cancelled meetings.
              </div>
            </div>
          </div>

          <!-- for mobile displays //-->
          <div class="mt-6 flex flex-col block lg:hidden">
            <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div v-if="meetsCancelled.length>0" class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <div v-for="(item, index) in meetsCancelled" :key="index" class="bg-white transition duration-150 ease-in-out p-4 mt-2 mb-2">
                  <a :href="`/attendeeDetails/${item.contact.companyId}`" target="new" rel="noopener" class="text-red-600 hover:text-red-400">{{ item.contact.name }}<br />{{ item.contact.companyName }}</a><br />
                  <div class="grid grid-cols-[90px_1fr] gap-x-2">
                    <span class="font-semibold">Tel:</span>
                    <a :href="`tel:${item.contact.tel}`" class="text-red-600 hover:text-red-400">{{ item.contact.tel }}</a>
                    <span class="font-semibold">Meeting:</span>
                    <span>{{ getMeetingDayDetails(item.timeslotId, true) }}</span>
                    <span class="font-semibold">Cancelled:</span>
                    <span>{{ formatDate(item.dtCancelled) }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                Any confirmed meetings that have been cancelled will be visible here.<br />
                You currently do not have any cancelled meetings.
              </div>
            </div>
          </div>

        </div>

					<modal v-show="showConfirmDeleteRequest" @submit="deleteRequest()" @cancel="showConfirmDeleteRequest = false" header="Confirm cancellation of meeting request" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
						<template v-slot:body>
							<div>Are you sure you want to cancel the meeting request ({{ meetingDetails.timeslot.timeStart }} - {{ meetingDetails.timeslot.timeEnd }}) with {{ meetingDetails.timeslot.meet.contact.name }} from {{meetingDetails.timeslot.meet.contact.companyName}}?</div>
						</template>
					</modal>

					<modal v-show="showConfirmDeclineRequest" @submit="deleteRequest()" @cancel="showConfirmDeclineRequest = false" header="Confirm declining of meeting request" affirmativeButtonLabel='Yes, decline' cancelButtonLabel="No">
						<template v-slot:body>
							<div>Are you sure you want to decline the meeting request ({{ meetingDetails.timeslot.timeStart }} - {{ meetingDetails.timeslot.timeEnd }}) by {{ meetingDetails.timeslot.meet.contact.name }} from {{meetingDetails.timeslot.meet.contact.companyName}}?</div>
						</template>
					</modal>

					<modal v-show="showConfirmAcceptRequest" @submit="acceptRequest()" @cancel="showConfirmAcceptRequest = false" header="Confirm meeting request" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
						<template v-slot:body>
							<div>Are you sure you want to accept the meeting request ({{ meetingDetails.timeslot.timeStart }} - {{ meetingDetails.timeslot.timeEnd }}) from {{ meetingDetails.timeslot.meet.contact.name }} from {{meetingDetails.timeslot.meet.contact.companyName}}?</div>
						</template>
					</modal>

					<modal v-show="showConfirmDeleteMeeting" @submit="deleteMeeting()" @cancel="showConfirmDeleteMeeting = false" header="Confirm cancellation of meeting" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
						<template v-slot:body>
							<div>Are you sure you want to cancel the meeting ({{ meetingDetails.timeslot.timeStart }} - {{ meetingDetails.timeslot.timeEnd }}) with {{ meetingDetails.timeslot.meet.contact.name }} from {{meetingDetails.timeslot.meet.contact.companyName}}?</div>
						</template>
					</modal>

					<modal v-show="showSendReminder" @submit="sendReminder()" @cancel="showSendReminder = false" header="Confirm sending reminder" affirmativeButtonLabel='Yes, confirm' cancelButtonLabel="No">
						<template v-slot:body>
							<div>Are you sure you want to send the reminder of this meeting ({{ meetingDetails.timeslot.timeStart }} - {{ meetingDetails.timeslot.timeEnd }}) to {{ meetingDetails.timeslot.meet.contact.name }} from {{meetingDetails.timeslot.meet.contact.companyName}}?</div>
						</template>
					</modal>

      </div>

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import Modal from "@/components/modal"

import BaseLayout from "../components/BaseLayout.vue";
import eventBus from '../utils/eventBus'

export default {
  name: "Register",
  components: {
    BaseLayout,
    Modal,
  },
  data() {
    return {
      shouldSkipPic: false,
      isLoaded: false,
      days: null,
      numTables: null,
      numTablesMax: null,
      timeslotsByDay: null,
      currentTab: "0", // 0 (day 1), 1 (day 2), 2 (cancelled meetings)
      timezone: "(CEST)",
      meets: null,
      meetsCancelled: null,
      contacts: null, // contacts for each meeting
      allSettings: null,
      schedulerEnabled: false,
      showConfirmDeleteRequest: false,
      showConfirmDeclineRequest: false,
      showConfirmAcceptRequest: false,
      showConfirmDeleteMeeting: false,
      showSendReminder: false,
      meetingDetails: {
        timeslot: {
          timeStart: null,
          timeEnd: null,
          meet: {
            contact: {
              name: null,
              email: null,
              tell: null,
              companyName: null,
            },
          }
        }
      },
      captainsLunchDayNumber: null,
    };
  },
  computed: {
    ...mapState(["user", 'keynotes']),
    currentTimeslots() {
      return this.timeslotsByDay[+this.currentTab + 1]
    }
  },
  async created() {
    try {
      this.allSettings = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload)
      this.schedulerEnabled = Boolean(Number(this.allSettings.schedulerEnabled))
      // if (this.user.companyId==7 || this.user.companyId==244) {this.schedulerEnabled = true} // added temporarily to enable scheduler buttons for testing, only for the test user with the company IDs 7 (TBS) and 244 (Infotechture)
      // this.homepageText = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload.mainpage_text)
    } catch (err) {
      console.log("err", err)
    }

    await this.reload()
    this.isLoaded = true
  },
  async mounted() {
    this.shouldSkipPic = !!this.user
    const { a:action = '', id:auth = null, cd:selectedDay = 0 } = this.$route.query
    this.currentTab = selectedDay // set the current day to whatever is selected in the querystring. If nothing is entered, use 0 (Thursday)

    if (auth) {
      try {
        const resp = await this.$http.get(`/api/scheduler/meetings/${auth}/action/meetingIdByAuth`)
        const selectedMeetingId = resp.data.meetingId
        // const selectedTimeslot = this.currentTimeslots.find(timeslot => timeslot.meet && timeslot.meet.id === selectedMeetingId)

        switch (action) {
          case '1':
            this.meetingDetails.delAction = 'accept'
            this.meetingDetails.timeslot.meet.id = selectedMeetingId
            this.acceptRequest()
            break
          case '2':
            this.meetingDetails.delAction = 'decline'
            this.meetingDetails.timeslot.meet.id = selectedMeetingId
            this.deleteRequest()
            break
        }

        // this.$router.push(`/scheduler?cd=${selectedDay}`)
      } catch (err) {
        console.log("err", err)
      }

      await this.reload() // make sure we have all the timeslots loaded
      this.currentTab = String(parseInt(this.currentTab) + 1)
      this.currentTab = String(parseInt(this.currentTab) - 1)

    }
  },
  methods: {
    async reload() {
      this.$store.dispatch('updateTimeslotRequest', []) // we remove the value of the time slot from the state
      let timeslots
      await this.$http.get("/api/scheduler/timeslots").then(res => {
        timeslots = res.data.timeslots
        this.days = [res.data.metaData.date_start.value, res.data.metaData.date_end.value] // always 2
        this.numTables = res.data.metaData.meeting_tables.value
        this.numTablesMax = res.data.metaData.meeting_tables_max.value // Including lobby seats
        this.timeslotsByDay = _.groupBy(timeslots, 'day')
      })
      const timeslotMap = _.keyBy(timeslots, 'id')

      await this.$http.get("/api/scheduler/meetings").then(res => {
        this.captainsLunchDayNumber = res.data.dayNumber

        const contactMap = _.keyBy(res.data.contacts, 'id')
        _.each([...res.data.meets, ...res.data.meetsCancelled], meet => {
          meet.isMeFrom = this.user.id === meet.fromId
          meet.isBlock = this.user.id === meet.fromId && meet.fromId === meet.toId

          if (!meet.isBlock) {
            meet.contact = contactMap[meet.isMeFrom ? meet.toId : meet.fromId]
            meet.contact.mailLink = `mailto:${meet.contact.email}`
            meet.contact.telLink = `tel:${meet.contact.tel}`

            // default to a profile pic outline. White with gray
            meet.contact.profilePic = meet.contact.profilePic || 'https://ui-avatars.com/api/?name=' + encodeURIComponent(meet.contact.name) + '&background=fff&color=aaa'
          }

        })
        this.meets = res.data.meets
        this.meetsCancelled = res.data.meetsCancelled

        _.each(this.meets, meet => {
          const timeslot = timeslotMap[meet.timeslotId]
          timeslot.meet = meet
        })

        _.each(timeslots, timeslot => {
          timeslot.state = 'available'
          timeslot.keynote = this.keynotes[timeslot.id]

          if ((timeslot.id==4) || (timeslot.id==5)) {
            timeslot.keynote = "Presentation by Paolo Tosoratti - refurbished hardware legislation"
          } else if ((timeslot.id==20) || (timeslot.id==21)) {
            timeslot.keynote = "Presentation by Paolo Tosoratti & Davide Polverini - refurbished hardware legislation"
          } else {
            timeslot.keynote = ""
          }

          if (this.captainsLunchDayNumber !== null) {
            if ((this.captainsLunchDayNumber === 0) && (timeslot.id >= 6 && timeslot.id <= 8)) {
              timeslot.keynote = "You are confirmed for the Captain's Lunch in the Rose Meeting Room"
            } else if ((this.captainsLunchDayNumber === 1) && (timeslot.id >= 22 && timeslot.id <= 24)) {
              timeslot.keynote = "You are confirmed for the Captain's Lunch in the Rose Meeting Room"
            }
          }

          if (timeslot.isFullyBooked) {
            timeslot.state = 'fullyBooked'
          } else if (timeslot.isTablesBooked) {
            timeslot.state = 'tablesBooked'
          }
          if (timeslot.meet && timeslot.meet.status === 0) {
            if (timeslot.meet.isBlock) {
              timeslot.state = 'blocked'
            } else {
              timeslot.state = 'pending'
            }
          } else if (timeslot.meet && timeslot.meet.status === 1) {
            timeslot.state = 'accepted'
            timeslot.isLobby = timeslot.meet.table >= this.numTables
            timeslot.table = timeslot.isLobby ? 'Lobby' : `Table: ${timeslot.meet.table}`
          }

          switch (timeslot.state) {
            case 'available':
            case 'tablesBooked':
              timeslot.cssContainer = 'border-gray-200 hover:border-gray-400 bg-white'
              timeslot.cssHeading = 'bg-gray-400'
              break;
            case 'blocked':
              timeslot.cssContainer = 'border-yellow-400 hover:border-yellow-600 bg-yellow-100'
              timeslot.cssHeading = 'bg-yellow-600'
              break;
            case 'pending':
              timeslot.cssContainer = 'border-red-400 hover:border-red-600 bg-red-100'
              timeslot.cssHeading = 'bg-red-600'
              break;
            case 'accepted':
              timeslot.cssContainer = 'border-green-400 hover:border-green-600 bg-green-100'
              timeslot.cssHeading = 'bg-green-600'
              break;
            case 'fullyBooked':
              timeslot.cssContainer = 'border-yellow-400 hover:border-yellow-600 bg-yellow-100'
              timeslot.cssHeading = 'bg-yellow-600'
              break;
          }
        })
      })

    },
    tabClass(index) {
      // console.log(this.currentTab)
      this.currentTab = String(this.currentTab) // Added by Brian: convert to string, because when using mobile, the value is passed as an integer and the === won't work, results in only displaying the cancelled meetings tab
      if (+index === +this.currentTab) return 'border-red-500 text-red-600 flex whitespace-nowrap border-b-2 py-4 px-1 font-medium'
      window.history.pushState({}, '', `/scheduler?cd=${this.currentTab}`) // added this so that if a tab is clicked, the URL changes as well and doesn't get stuck on a specific when the page is reloaded
      return 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1font-medium'
    },

    formatDay(dateStr, type) { // added by Brian to format the date in the scheduler tabs
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

      const dateObj = new Date(dateStr)
      const day = daysOfWeek[dateObj.getDay()]
      const month = months[dateObj.getMonth()]
      const date = dateObj.getDate()

      return `${day} ${month} ${date}`
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${day}/${month}/${year} at ${hours}:${minutes}`
      },
    getMeetingDayDetails(timeslotId, isShort = false) {
      for (const day in this.timeslotsByDay) {
        const timeslot = this.timeslotsByDay[day].find(slot => slot.id === timeslotId)
        if (timeslot) {
          const dayName = timeslot.day === 1 ? 'Thu Oct 10' : 'Fri Oct 11'

          if (isShort) {return `${dayName} ${timeslot.timeStart} - ${timeslot.timeEnd}`}
          
          return `${dayName} from ${timeslot.timeStart} - ${timeslot.timeEnd}`
        }
      }
      return null // Return null if no timeslot is found
    },

    async toggleTimeslotBlock(timeslotId, action) {
      const payload = {
        timeslotId
      }

      const endpoint = action === 'block' ? "/api/scheduler/blockTimeslot" : "/api/scheduler/unblockTimeslot"
      const response = await this.$http.post(endpoint, payload).then(res => res.data.meet)
      await this.reload()

      // TO DO: fix reloading. It works but the schedule slots are not displayed properly
      // Hacky: check on which tab the action is done, switch to another tab and back again. This causes a re-render of the time slots. We need to convert to integer and back to string to make sure all works.
      this.currentTab = String(parseInt(this.currentTab) + 1)
      this.currentTab = String(parseInt(this.currentTab) - 1)
    },

    createMeetingRequest(timeslot) {
      this.$store.dispatch('updateTimeslotRequest', timeslot).then(() => {
        this.$router.push('/Attendees')
      });
    }, 

    confirmDeleteRequest(timeslot, delAction) {
      this.meetingDetails = {
        timeslot,
        delAction,
      }
      delAction === 'cancel' ? this.showConfirmDeleteRequest = true : this.showConfirmDeclineRequest = true
    },
    confirmAcceptRequest(timeslot) {
      this.meetingDetails = {
        timeslot,
        delAction:'accept',
      }
      this.showConfirmAcceptRequest = true
    },
    confirmDeleteMeeting(timeslot) {
      this.meetingDetails = {
        timeslot,
        delAction:'delete',
      }
      this.showConfirmDeleteMeeting = true
    },
    confirmSendReminder(timeslot) {
      this.meetingDetails = {
        timeslot,
        delAction:'delete',
      }
      this.showSendReminder = true
    },

    async deleteRequest() {
      try {
        const url = `/api/scheduler/meetings/${this.meetingDetails.timeslot.meet.id}/action/${this.meetingDetails.delAction}`
        const response = await this.$http.post(url)
        const meet = response.data.meet
        await this.reload()
        this.meetingDetails.delAction == 'cancel' ? this.showConfirmDeleteRequest = false : (this.meetingDetails.delAction == 'decline' ? this.showConfirmDeclineRequest = false : null);
      } catch (error) {
        console.error('Error during request cancellation:', error)
      }
    },

    async acceptRequest() {
      try {
        const url = `/api/scheduler/meetings/${this.meetingDetails.timeslot.meet.id}/action/accept`
        const response = await this.$http.post(url)
        const meet = response.data.meet
        await this.reload()
        this.showConfirmAcceptRequest = false
      } catch (error) {
        console.error('Error during request cancellation:', error)
      }
    },

    async deleteMeeting() {
      try {
        const url = `/api/scheduler/meetings/${this.meetingDetails.timeslot.meet.id}/action/cancel`
        const response = await this.$http.post(url)
        const meet = response.data.meet
        await this.reload()
        this.showConfirmDeleteMeeting = false
      } catch (error) {
        console.error('Error during request cancellation:', error)
      }
    },

    async sendReminder() {
      try {
        const response = await this.$http.post(`/api/scheduler/meetings/${this.meetingDetails.timeslot.meet.id}/action/reminder`)
        const meet = response.data.meet

        if (response.data.status !== 200) {
          eventBus.$emit("createSnackbar", {
            type: "error",
            title: "There was a problem sending your reminder",
            body: "Please try again. If this occur keeps occurring, please contact European Broker Meeting",
            timeoutInMS: 5000,
          })
        } else {
          eventBus.$emit("createSnackbar", {
            type: "success",
            title: "Meeting request reminder was successfully sent",
          })
        }

        await this.reload()
        this.showSendReminder = false
      } catch (error) {
        console.log("Error sending reminder")
      }
    }
  },

};
</script>
<style scoped>
.schedule-header {
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
}
</style>
