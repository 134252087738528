<template>
  <div
    class="snackbar rounded-md p-4 fixed right-4 top-4 shadow"
    :class="snackbarClass"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          v-if="type === 'success'"
          class="h-5 w-5"
          :class="snackbarIconClass"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>

        <svg
          v-else-if="type === 'error'"
          class="h-5 w-5 text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <h3 class="text-sm leading-5 font-medium" :class="snackbarTitleClass">
          {{ title }}
        </h3>
        <div
          v-if="body"
          class="mt-2 text-sm leading-5"
          :class="snackbarBodyClass"
        >
          <p v-html="body" />
        </div>
        <!--  <div class="mt-4">
	        <div class="-mx-2 -my-1.5 flex">
	          <button class="ml-3 px-2 py-1.5 rounded-md text-sm leading-5 font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
	            Dismiss
	          </button>
	        </div>
	      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  props: {
    type: {
      type: String,
      default: "success",
    },
    title: String,
    body: String,
  },
  computed: {
    snackbarClass() {
      switch (this.type) {
        case "success":
          return "bg-green-100";
        case "error":
          return "bg-red-100";
      }
      return "";
    },
    snackbarIconClass() {
      switch (this.type) {
        case "success":
          return "text-green-400";
        case "error":
          return "text-red-400";
      }
      return "";
    },
    snackbarTitleClass() {
      switch (this.type) {
        case "success":
          return "text-green-800";
        case "error":
          return "text-red-800";
      }
      return "";
    },
    snackbarBodyClass() {
      switch (this.type) {
        case "success":
          return "text-green-700";
        case "error":
          return "text-red-700";
      }
      return "";
    },
  },
  methods: {},
};
</script>
