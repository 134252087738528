import _ from "lodash"
import Password from "vue-password-strength-meter"

import { mapState, mapActions } from "vuex"
import eventBus from "../utils/eventBus"

export default {
  data: () => ({
    showContactEditDropdown: false, //only for one of 2 views.
    showChangePasswordModal: false,
    resetPasswordInput: null,
    passwordFeedback: {},
    passwordScore: null,
  }),
  components: {
    Password,
  },
  computed: {
    ...mapState([]),
    passwordSuggestion() {
      if (!this.passwordFeedback.suggestions || !this.passwordFeedback.suggestions.length) return ""
      return this.passwordFeedback.suggestions[0]
    },
    passwordSubmitDisabled() {
      return this.passwordScore < 2
    },
  },
  methods: {
    ...mapActions(["resetPassword"]),
    async submitChangePassword(token = null) {
      const resp = await this.resetPassword({ password: this.resetPasswordInput, token })

      if (resp.data.status !== 200) {
        eventBus.$emit("createSnackbar", {
          type: "error",
          title: "Something went wrong",
          body: "Password could not be updated. Please try again. <br/> If the problem persists please contact European Broker Meeting",
          timeoutInMS: 5000,
        })
      } else {
        eventBus.$emit("createSnackbar", {
          type: "success",
          title: token ? "Password succesfully updated. Please login with your newly created password" : "Password succesfully updated",
        })

        if (token) {
          // remove ?reset=xxx from url
          this.$router.replace({ query: {} })
        }

        this.showChangePasswordModal = false
      }
    },
    doShowChangePasswordModal() {
      this.resetPasswordInput = null
      this.resetPasswordInputVerify = null
      this.showChangePasswordModal = true
      this.showContactEditDropdown = false
    },
    passwordFeedBack(feedback) {
      this.passwordFeedback = feedback
    },
    passwordScoreFeedback(score) {
      this.passwordScore = score
    },
  },
}
