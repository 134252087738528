import VueRecaptcha from 'vue-recaptcha';

export default {

  data: () => ({
    recaptchaKey: "6LeI88oZAAAAALmOb9F9e_jGqm-as5XOS4EPkwZJ", // v2
    captchaResponse: null, 
    // recaptchaKey: "6Ldp8MoZAAAAABxjylgjXdNZccxftDykLVeOKHoW", // v3
  }),
  components: {
    VueRecaptcha,
  },
  methods: {
    onVerify (captchaResponse) {
      this.captchaResponse = captchaResponse;
    },
  }
}