<template>
  <!-- <base-layout :do-skip-header="true" :do-include-splash="true" :do-skip-pic="true"> -->
  <base-layout :do-skip-header="false" :do-skip-pic="skipHeaderOnMobile" :doIncludeExtraContent="true">
    <!-- <template #splash>
      <div class="home-hero-container">
        <div class="home-hero" style="background-image:url('/images/main_banner_cyprus1.png')"></div>

        <div class="homeImageText">The European Broker Meeting<br>
        October 10 &amp; 11, 2023 at the Parklane Cyprus</div>

        <a href='https://www.thebrokersite.com' target="_blank" class="homePoweredBy2"
          style="background-image:url('/images/powered_by_tbs_new.png')"
          title="The European Broker Meeting is powered by www.TheBrokerSite.com">
        </a>
        <router-link to="/register"
          class="register-home rounded-md border border-transparent bg-red-600 py-3 sm:py-4 px-8 text-xl sm:text-2xl font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">Register
        </router-link>


        <div v-if="lastRegistered" class="home-ticker-container bg-red-600 text-white text-sm whitespace-nowrap">
          <div class="home-ticker  py-2 ">{{ lastRegistered }}</div>
        </div>


      </div>

    </template> -->
    <template #main>
      <div class="hidden lg:block relative max-w-7xl mx-auto mt-6">
        <div v-if="lastRegistered" class="home-ticker-container bg-red-600 text-white text-sm whitespace-nowrap overflow-hidden">
          <div class="home-ticker py-4 ">
            <span>{{ lastRegistered }}</span>
          </div>
        </div>
      </div>

      <!-- <h1 class="mt-10 text-3xl font-bold tracking-tight text-black sm:text-5xl">Join the leading get-together for the
        computer trading industry.</h1> -->
      <div class="hidden sm:block text-center mt-16 text-md leading-7 text-gray-700">
        <h1 class="text-5xl text-bold">The leading get-together for the IT trading industry</h1><br>
        <span>
          The European Broker Meeting is the leading get-together for the IT trading industry with <strong>more than 850 traders attending in 2024</strong>. The event offers Computer Brokers, Traders, IT Wholesalers and Refurbishers the opportunity to meet new and existing customers and suppliers all in one place. The European Broker Meeting is open to all companies trading Desktops, Laptops, Tablets, Servers, Printers, Mobile Phones, Networking and POS Equipment.
          <br><br>
          <strong>The European Broker Meeting’s Impact on IT4Kids</strong><br>
          During the 20th edition of the European Broker Meeting, we raised an impressive amount through ticket sales. We are proud to announce that this year we donated an incredible €25,000 to IT4Kids!
          <br><br>
          <strong>We are excited to announce that the 21st European Broker Meeting will take place on November 5th, 6th, and 7th 2025 at the Clayton Hotel Burlington Road in Dublin. Registration will start in January 2025.</strong>
        </span>
        <!-- <span v-html="homepageText"></span> -->
      </div>

      <!-- For small screens show the large buttons //-->
      <div v-if="!user" class="block sm:hidden">
        <img class="max-h-32 w-full object-contain" :src="mainSponsor.logoURL" :alt="mainSponsor.name"/>
        <div class="max-w-xs mx-auto text-xl">
          <div class="text-center mb-4">Let's get started!</div>
          
          <div class="grid grid-cols-2 gap-4">
            <div class="mainMenuButt" @click="goPage('program');">
              <img src="/images/program.png" class="mobileButton">
              <div class="text-center text-xl">Program</div>
            </div>
            <div class="mainMenuButt" @click="goPage('attendees');">
              <img src="/images/attendees.png" class="mobileButton">
              <div class="text-center text-xl">Attendees</div>
            </div>
            <div class="mainMenuButt" @click="goPage('testimonials');">
              <img src="/images/testimonials.png" class="mobileButton">
              <div class="text-center text-xl">Testimonials</div>
            </div>
            <div class="mainMenuButt" @click="goPage('register');">
              <img src="/images/register.png" class="mobileButton">
              <div class="text-center text-xl">Register</div>
            </div>
            <div class="col-span-2 mt-8 text-sm text-center">Please sign in for more options</div>
          </div>
        </div>        
      </div>
      <div v-else class="block sm:hidden">
        <img class="max-h-32 w-full object-contain" :src="mainSponsor.logoURL" :alt="mainSponsor.name"/>
        <div class="max-w-xs mx-auto text-xl">
          <div class="text-center mb-4">Let's get started, {{user.name}}!</div>
          
          <div class="grid grid-cols-2 gap-4">
            <div class="mainMenuButt" @click="goPage('scheduler');">
              <img src="/images/scheduler.png" class="mobileButton">
              <div class="text-center text-xl">Scheduler</div>
            </div>
            <div class="mainMenuButt" @click="goPage('program');">
              <img src="/images/program.png" class="mobileButton">
              <div class="text-center text-xl">Program</div>
            </div>
            <div class="mainMenuButt" @click="goPage('attendees');">
              <img src="/images/attendees.png" class="mobileButton">
              <div class="text-center text-xl">Attendees</div>
            </div>
            <div class="mainMenuButt" @click="goPage('scan');">
              <img src="/images/scan.png" class="mobileButton">
              <div class="text-center text-xl">Scan Lead</div>
            </div>

            <div class="col-span-2 mt-8 mx-auto bg-no-repeat bg-center bg-contain w-32 h-32">
               <!-- Google's QR code generation service is no longer actively maintained, switched to QR Server API for QR code generation //-->
              <img :src='"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://www.europeanbrokermeeting.com/checkin?id=" + user.id'> 
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";

export default {
  name: "Home",
  components: {
    BaseLayout,
  },
  data() {
    return {
      isUserDropdownOpen: false,
      lastRegistered: null,
      homepageText: null,
      allSettings: null,
      skipHeaderOnMobile: true,
      sponsors: [],
      mainSponsor: null, 
    };
  },
  mounted() {
    this.handleResize(); // Call once on mount to set initial state
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    handleResize() {
      const isMobile = window.matchMedia("(max-width: 640px)").matches;
      this.skipHeaderOnMobile = isMobile;
    },

    goPage(page) {
      this.$router.push({ path: page })
    },
  },
  async created() {
    const sponsors = await this.$http.get("/api/misc/sponsors").then(res => res.data.payload)
    const sponsorDTOs = _.map(sponsors, sponsor => {
      return _.extend({}, sponsor, {
        logoURL: `${window.location.origin}/images/sponsors/${sponsor.logo}`,
        sponsorType: `${sponsor.type} Sponsor`
      })
    })
    this.mainSponsor = _.find(sponsorDTOs, {type: "main"})
    // this.sponsors = _.difference(sponsorDTOs, [this.mainSponsor])

    try {
      const names = await this.$http.get("/api/misc/lastRegistered").then(res => res.data.payload)

      // this.lastRegistered = `Latest registered attendees: \xA0 \xA0  ${names.join(" \xA0 \xA0 • \xA0 \xA0 ")}`
      this.lastRegistered = `Winners of the free #EBM2025 tickets: \xA0 \xA0  C&M IT Remarketing \xA0 \xA0 • \xA0 \xA0 Aucnet \xA0 \xA0 • \xA0 \xA0 Ecotech Solutions \xA0 \xA0 • \xA0 \xA0 Hibco General LLC \xA0 \xA0 • \xA0 \xA0 Rapid Solutions \xA0 \xA0 \xA0 \xA0 • \xA0 \xA0 \xA0 \xA0 At the 20th #EBM2024 we raised €25,000 for IT4Kids! \xA0 \xA0 \xA0 \xA0 • \xA0 \xA0 \xA0 \xA0 The 21st #EBM2025 will be held on November 5, 6 & 7 2025 in Dublin!`
    } catch (err) {
      console.log("err", err)
    }

    try {
      this.allSettings = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload)
      this.homepageText = this.allSettings.mainpage_text
      // this.homepageText = await this.$http.get("/api/misc/allSettings").then(res => res.data.payload.mainpage_text)
    } catch (err) {
      console.log("err", err)
    }

  },
};
</script>

<style scoped>
.home-hero-container {
  position: relative;
  width: 100%;
  padding-bottom: 28%;
  max-height: 400px;
  overflow: hidden;
}

.home-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center 10%;
  z-index: 1
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(60%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    transform: translate3d(60%, 0, 0); /* Start just outside of the right edge */
  }
  100% {
    transform: translate3d(-100%, 0, 0); /* Move to twice the width to the left */
  }
}

.home-ticker-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  z-index: 2;
}

.home-ticker {
  position: absolute;
  bottom: 0;
  height: 42px;
  z-index: 3;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}

.mainMenuButt {width: 139px; height: 139px; background-color: #e9e9e9; margin: 5px; padding: 5px;}
.mobileButton {
  width: 70px;
  height: 70px;
  margin: 15px auto 5px auto;
  background-size: contain;
  display: block;
}
</style>
```