<template>
  <base-layout :do-skip-header="true">
    <template #main>
      <h1 class='text-3xl md:text-4xl font-bold mt-8'>
        Registration European Broker Meeting
      </h1>
      <h3 class="mt-2 mb-8">€ 995 per attendee all in except for hotel and flight</h3>
      <Transition name="fade" mode="out-in">
        <component :is="activeStep" :companyProfile="companyProfile" :attendees="attendees" />
      </Transition>
    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import BaseLayout from "../components/BaseLayout.vue";
import RegisterStep1 from "../components/RegisterStep1.vue";
import RegisterStep2 from "../components/RegisterStep2.vue";
import RegisterStep2A from "../components/RegisterStep2A.vue";
import RegisterStep3 from "../components/RegisterStep3.vue";
import RegisterStep4 from "../components/RegisterStep4.vue";

export default {
  name: "Register",
  components: {
    BaseLayout,
    RegisterStep1,
    RegisterStep2,
    RegisterStep2A,
    RegisterStep3,
    RegisterStep4,
  },
  data() {
    return {
      step: 1,
      companyProfile: this.emptyCompany(),
      attendees: [],
    }
  },
  created() {
    const utmParams = this.getUtmParams()
    const refParams = {
      referrer: document.referrer || 'Direct',
      dateClicked: this.getCurrentDateTime(),
    }

    if (utmParams.utm_source || utmParams.utm_medium || utmParams.utm_campaign) {
      localStorage.setItem('utmParams', JSON.stringify(utmParams));
      localStorage.setItem('refParams', JSON.stringify(refParams));
    }
  },
  computed: {
    activeStep() {
      if (this.step === 1) return RegisterStep1
      if (this.step === 2) return RegisterStep2
      if (this.step === 3) return RegisterStep2A
      if (this.step === 4) return RegisterStep3
      if (this.step === 5) return RegisterStep4
      throw new Error(`Step not defined for ${this.step}`)
    },
  },
  methods: {
    emptyCompany() {
      return {
        companyName: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        website: null,
        tel: null,
        vat: null,
        tbsCompanyId: null,
        profile: null,
        categories: [],
        trading: null,
        heardThrough: "Google or other search engine",
      }
    },
    toStep({ step, companyProfile = null, attendees = null, }) {
      if (companyProfile) {
        this.companyProfile = companyProfile
      }
      if (attendees) {
        this.attendees = attendees
      }

      this.step = step;
    },
    getUtmParams() {
      return {
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign
      }
    },
    getCurrentDateTime() {
      const now = new Date()
      const pad = num => String(num).padStart(2, '0')
      const year = now.getFullYear()
      const month = pad(now.getMonth() + 1)
      const day = pad(now.getDate())
      const hours = pad(now.getHours())
      const minutes = pad(now.getMinutes())
      const seconds = pad(now.getSeconds())
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
