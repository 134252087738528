<template>
  <base-layout :do-skip-header="false" :doSkipPic="shouldSkipPic">
    <template #main>
      <Popover ref="popover_attendee" class=""></Popover>

      <div class="flex flex-col md:flex-row justify-between items-center md:items-start my-8">
        <h1 class='text-3xl md:text-4xl font-bold'>
          Attendees of the 2024 EBM in Cyprus
        </h1>
        <div v-if="!isTimeslotSelected()">
          <a :href="fileUrl" download="attendees.csv" ref="downloadLink" class="hidden">Download</a>
          <button @click="downloadCSV" class="mt-4 md:mt-0 rounded-md border border-transparent bg-red-700 py-2 px-4 text-lg sm:text-lg font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 shadow-lg">
            Download attendee list
          </button>
        </div>
      </div>

      <form @submit.self.prevent="doSearch">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 sm:col-span-12 lg:col-span-7">
            <label for="cSearch" class="form-label block truncate">Search for Companies, Contact Persons or
              Specialty</label>
            <input v-model="qSearch" id="cSearch" class="form-input mt-1 block w-full" />
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <label for="cPref" class="form-label block">Preference</label>
            <select v-model="qPref" id="cPref" class="form-select mt-1 block w-full">
              <option v-for="item in preferences" :value="item.id" :key='item.id'>{{ item.value }}</option>
            </select>
          </div>

          <div class="col-span-12 sm:col-span-6 lg:col-span-2">
            <label for="cTradingType" class="form-label block">Trading Type</label>
            <select v-model="qTradingType" id="cTradingType" class="form-select mt-1 block w-full">
              <option v-for="item in tradingTypes" :value="item.id" :key='item.id'>{{ item.value }}</option>
            </select>
          </div>

          <div class="col-span-12 sm:col-span-12 lg:col-span-1">
            <button @click="doSearch" type="button"
              class="btn btn-primary float-right lg:float-left lg:mt-6">Search</button>
          </div>
        </div>
      </form>

      <div v-if="attendees && attendees.length">
        <div v-if="isTimeslotSelected()" class="mt-8">
          <span class="text-red-600"><strong>You are scheduling a meeting on {{ timeslotRequest.day === 1 ? 'Thursday' : 'Friday' }} from {{timeslotRequest.timeStart}} - {{timeslotRequest.timeEnd}}</strong></span><br>
          Please choose the company you would like to request your meeting with below.<br>You can narrow down the list by using the search options above.
          </div>
        <div class="mt-6 flex flex-col hidden lg:block">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
              class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th class="table-cell-header pr-0 pl-3"><!-- emblem gold/trusted --></th>
                    <th class="table-cell-header pl-1">
                      Company Name</th>
                    <th class="table-cell-header">Country</th>
                    <th class="table-cell-header">Company
                      Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in attendeesEnriched" :key="index" @click='navToAttendee(item.id, $event)'
                    class="bg-white  transition duration-150 ease-in-out " :class="item.clazzContainer"
                    @mouseover="changePopoverData($event, item)" @mouseout="closePopoverData">
                    <td class="table-cell text-gray-900 pr-0 pl-3">

                      <div v-if="item.isDiamond" class='member_diamond'
                        title="This company is a 'Diamond Member'. Diamond members have been on www.EuropeanBrokerMeeting.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                      </div>
                      <div v-if="item.isGold" class='member_gold'
                        title="This company is a 'Gold Member'. Gold members have been on www.EuropeanBrokerMeeting.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                      </div>
                      <div v-if="item.isTrusted" class='member_trusted'
                        title="This company is a 'Trusted Member'. Trusted members have been on www.EuropeanBrokerMeeting.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                      </div>
                    </td>
                    <td class="table-cell text-gray-900 pl-1">
                      <span class='inline-block font-semibold truncate hover-higlight max-w-48 align-middle'
                        :class="item.clazz">{{ item.name
                        }}</span>
                    </td>
                    <td class="table-cell text-gray-500">{{ item.country }}</td>
                    <td class="table-cell text-gray-900">
                      <div class="max-w-144 truncate">{{ item.description }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="mt-12 flex flex-col block lg:hidden">
          <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div
              class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="w-full table-fixed">
                <thead>
                  <tr>
                    <th class="table-cell-header pr-0 pl-3 ">Companies</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in attendeesEnriched" :key="index" @click='navToAttendee(item.id, $event)'
                    class="bg-white hover:bg-red-50 cursor-pointer transition duration-150 ease-in-out hover-higlight-anchor">
                    <td class="table-cell  pr-0 pl-3 ">
                      <div class='truncate'>
                        <div v-if="item.isDiamond" class='float-left mr-2 member_diamond'
                          title="This company is a 'Diamond Member'. Diamond members have been on www.EuropeanBrokerMeeting.com for 20 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                        </div>
                        <div v-if="item.isGold" class='float-left mr-2 member_gold'
                          title="This company is a 'Gold Member'. Gold members have been on www.EuropeanBrokerMeeting.com for 10 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                        </div>
                        <div v-if="item.isTrusted" class='float-left mr-2 member_trusted'
                          title="This company is a 'Trusted Member'. Trusted members have been on www.EuropeanBrokerMeeting.com for 3 or more years, we do not have any unsolved complaints on file and they offer a 1 month return to base warranty.">
                        </div>
                        <span class='text-gray-900 hover-higlight font-semibold' :class="item.clazz">{{ item.name }}</span>
                      </div>
                      <div class="truncate">{{ item.description }}</div>
                      <div class="truncate text-gray-500">{{ item.country }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="attendees && !attendees.length" class="text-sm leading-5 text-gray-700 font-bold mt-4">
        No results found for this query. Please broaden your search.
      </div>

    </template>
  </base-layout>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";

import BaseLayout from "../components/BaseLayout.vue";
import Popover from "../components/Popover.vue";

export default {
  name: "Register",
  components: {
    BaseLayout,
    Popover,
  },
  data() {
    return {
      attendees: null,
      qSearch: "",
      qPref: "",
      qTradingType: "",
      preferences: [
        { id: "", value: "None" },
        { id: 1, value: "PC/Laptops" },
        { id: 2, value: "Printers" },
        { id: 3, value: "Server/Storage" },
        { id: 4, value: "Networking/Telecom" },
        { id: 5, value: "Software" },
        { id: 6, value: "Electronics" },
        { id: 7, value: "Mobile" },
        { id: 8, value: "POS/BarCode/Banking" },
        { id: 9, value: "Components" },
      ],
      tradingTypes: [
        { id: "", value: "None" },
        { id: "new equipment", value: "New" },
        { id: "used equipment", value: "Used" },
      ],
      fileUrl: '',
      shouldSkipPic: false,
    };
  },
  computed: {
    ...mapState(["user", "timeslotRequest"]),
    attendeesEnriched() {
      return _.map(this.attendees, att => {
        return {
          ...att,
          clazzContainer: this.user && att.status === 3 ? "cursor-pointer  hover:bg-red-50 hover-higlight-anchor" : "",
          clazz: att.status === 3 ? "text-red-700 " : "",
          memberAsText: att.isDiamond ? "TBS Diamond Member" : att.isGold ? "TBS Gold Member" : att.isTrusted ? "TBS Trusted Member" : "TBS Regular Member",
        };
      })
    },
  },
  async mounted() {
    this.shouldSkipPic = !!this.user
    // TO DO: we need to check if a timeslotId is given and if so, we will not include any companies that do not have contacts with that available timeslot
    await this.doSearch()
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        // reset the timeslot object if the user didn't come from the scheduler or the company details
        if (this.$store.state.route.from.name != 'scheduler' && this.$store.state.route.from.name != 'companyDetail') {
          this.$store.dispatch('updateTimeslotRequest', [])
        }
      },
    },
  },

  methods: {
    changePopoverData(event, item) {
      if (item.status !== 3) return;
      this.$refs.popover_attendee.changeData(event.currentTarget, item);
    },
    closePopoverData() {
      this.$refs.popover_attendee.close()
    },
    async doSearch() {
      const payload = {
        search: this.qSearch.trim() || null,
        pref: this.qPref || null,
        tradingType: this.qTradingType || null,
      }
      this.attendees = await this.$http.post("/api/misc/attendees", payload).then(res => res.data.payload)
    },
    async downloadCSV() {

      const response = await this.$http({
        url: '/api/misc/attendeesAsCSV',
        method: 'GET',
        responseType: 'blob',
      })
      this.fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      this.$nextTick(() => this.$refs.downloadLink.click());

    },
    navToAttendee(id, event) {

      if (event.ctrlKey || event.metaKey) return window.open(this.$router.resolve({ name: 'companyDetail', params: { id } }).href, '_blank');
      this.$router.push({ name: 'companyDetail', params: { id } })
    },
    isTimeslotSelected() {
      return !!this.timeslotRequest.id && (this.$store.state.route.from.name === 'scheduler' || this.$store.state.route.from.name === 'companyDetail')
    },
  }
};
</script>
<style scoped>
.member_trusted {
  width: 24px;
  height: 24px;
  background: url("../assets/trusted_small.png");
  background-size: cover;
}

.member_gold {
  width: 24px;
  height: 24px;
  background: url("../assets/gold_small.png");
  background-size: cover;
}

.member_diamond {
  width: 24px;
  height: 24px;
  background: url("../assets/member_diamond.png");
  background-size: cover;
}

.excel_icon {
  width: 24px;
  height: 24px;
  background: url("../assets/excel.png");
  background-size: cover;
}
</style>
```