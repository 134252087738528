<template>
  <transition name="modal-fade">
    <div class="fixed top-4 right-0 left-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10" >
      <!--
		    Background overlay, show/hide based on modal state.
		    Entering: "ease-out duration-300"
		      From: "opacity-0"
		      To: "opacity-100"
		    Leaving: "ease-in duration-200"
		      From: "opacity-100"
		      To: "opacity-0"
		  -->
      <div class="fixed inset-0 transition-opacity opacity-100">
        <div class="absolute inset-0 0 opacity-75" @click="cancel" />
      </div>

      <!--
		    Modal panel, show/hide based on modal state.
		    Entering: "ease-out duration-300"
		      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		      To: "opacity-100 translate-y-0 sm:scale-100"
		    Leaving: "ease-in duration-200"
		      From: "opacity-100 translate-y-0 sm:scale-100"
		      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  -->
      <div
        class="modal-panel bg-white rounded-lg px-4 pt-4 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6 opacity-100 translate-y-0 scale-100 border"
        :class="customCssClasses"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <!-- class="sm:flex sm:items-start"-->
          <div class="mt-3 sm:mt-0 sm:ml-2 text-left">
            <div v-if="!doSkipHeader" class="-mt-9 sm:-mt-8 -ml-9 -mr-6 pt-5 pl-9 pr-4 bg-gray-100 pb-2" >
              <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900" >{{ header }}</h3>
            </div>
            <div class="modal-body">
              <p class="text-sm leading-5 text-gray-500 mb-2">
                <slot name="body"> Some body here </slot>
              </p>
            </div>
          </div>
        </div>
        <div v-if="!doSkipFooter" class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" >
          <slot name="footer" />

          <span v-if="!hideSubmit" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              :disabled="disableSubmit"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              :class="submitClass"
              @click="submit"
            >
              {{ affirmativeButtonLabel }}
            </button>
          </span>
          <span v-if="!doSkipCancel" class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto" >
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-blue-500/50 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              @click="cancel">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    doSkipHeader: Boolean,
    doSkipFooter: Boolean,
    doSkipCancel: Boolean,
    header: String,
    affirmativeButtonLabel: String,
    customCssClasses: String,
    disableSubmit: Boolean,
    hideSubmit: Boolean,
  },
  computed: {
    submitClass() {
      if (this.disableSubmit) {
        return 'border-gray-400 bg-gray-400 cursor-default';
      }
      return 'bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-red-500/50 ';
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      if (this.disableSubmit) return 
      this.$emit('submit');
    },
  },
};
</script>

<style></style>
