<template>
  <div v-if="!scannedCode" class="relative">
    <video ref="video" class="w-full h-auto" @play="onPlay"></video>
    <canvas ref="canvas" class="hidden"></canvas>
    <button v-if="scanStopped" @click='scanAgain' type="button" class="mt-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Start Scan</button>
    <button v-else @click='scanAgain' type="button" class="mt-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Reset Scan</button>
    <button v-if="!scanStopped" @click='stopScan' type="button" class="ml-3 mt-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Stop Scan</button>
    <!-- <p v-if="scannedCode">Scanned Code: {{ scannedCode }}</p> -->
  </div>
  <div v-else>
    <div v-if="!scanError" class="mt-8">
      <div>
        <img v-if='profilePicLarge' :src='profilePicLarge' />
        <div v-else>No picture available</div>
      </div>
      <span class="text-2xl text-bold">{{contactName}}</span><br>
      {{companyName}}
      <div class="mt-4 grid grid-cols-[90px_1fr] text-left">
        <div class="col-span-2">Main Interests:<br>{{categories}}</div>
        <div class="col-span-2 mb-4">Trading mostly {{trading}} equipment</div>

        <div>Mobile</div>
        <div>{{mobile}}</div>

        <div>Email</div>
        <div>{{email}}</div>
      </div>
      <div class="my-4 text-left">
        Additional notes:<br>
        <textarea v-model="notes" id="notes" name="notes" rows="5" class="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-400 disabled:ring-gray-20"></textarea>
      </div>
    </div>
    <div v-else class="justify center">
      <strong>There was an error scanning.</strong><br>
      Most likely because the QR code you scanned was invalid.<br><br>
      <button @click='scanAgain' type="button" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Scan Again</button>
    </div>

    <div v-if="saveError" class="my-2 text-bold text-red-500">
      This lead could not be added, possibly because they are already in your list.
    </div>
    <button v-if="!saveError && !scanError" @click='saveLead' type="button" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Add to Leads</button>
    <button v-if="!scanError" @click='scanAgain' type="button" class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Scan Again</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { loadScript } from '@/utils/loadScript'

export default {
  data() {
    return {
      scanning: false,
      contactName: null,
      companyName: null,
      profilePicLarge: null,
      categories: null,
      trading: null,
      mobile: null,
      email: null,
      notes: null,
      selectedContactId: null,
      scanError: false,
      saveError: false,
      scanStopped: false,
    };
  },
  computed: {
    ...mapGetters(['scannedCode']),
  },
  async mounted() {
    await this.initialize()
  },
  methods: {
    ...mapActions(['updateScannedCode']),
    async initialize() {
      if (this.stream) {
        // Stop the current stream if it's already running
        this.stream.getTracks().forEach(track => track.stop())
      }
      await loadScript('https://cdn.jsdelivr.net/npm/jsqr/dist/jsQR.js');
      const video = this.$refs.video
      this.stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
      video.srcObject = this.stream
      video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
      video.play()
      this.scanStopped = false
    },
    onPlay() {
      if (!this.scanning) {
        this.scanning = true
        this.scan()
      }
    },
    scan() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      const tick = () => {
        if (!this.scanStopped) {
          if (video.readyState === video.HAVE_ENOUGH_DATA) {
            canvas.height = video.videoHeight
            canvas.width = video.videoWidth
            context.drawImage(video, 0, 0, canvas.width, canvas.height)

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
            const code = window.jsQR(imageData.data, imageData.width, imageData.height)

            if (code) {
              const queryString = code.data.split('?')[1]
              const params = new URLSearchParams(queryString)
              const id = params.get('id')
              this.updateScannedCode(code.data)
              this.scanning = false
              this.getScannedContactDetails({ id })
            } else {
              requestAnimationFrame(tick)
            }
          } else {
            requestAnimationFrame(tick)
          }
        }
      }

      requestAnimationFrame(tick)
    },
    async getScannedContactDetails(id) {
      try {
        const resp = await this.$http.get(`/api/companies/fetchScannedContact/${id.id}`)
        this.selectedContactId = id.id
        this.contactName = resp.data.contact.name
        this.companyName = resp.data.company.name
        this.profilePicLarge = resp.data.contact.profilePicLarge
        this.mobile = resp.data.contact.tel
        this.email = resp.data.contact.email
        const preferences = JSON.parse(resp.data.company.preferences)
        this.trading = preferences.trading
        this.categories = this.getCategoryNames(preferences.categories)
      } catch (error) {
        this.scanError = true
        // console.log(error)
      }
    },
    scanAgain() {
      this.scanning = false
      this.scanError = false
      this.scanStopped = false
      this.updateScannedCode(null)
      this.initialize()
    },
    stopScan() {
      if (this.stream) {
        this.scanStopped = true
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null
        const video = this.$refs.video
        video.srcObject = null
        const canvas = this.$refs.canvas
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
      }
    },    
    async saveLead() {
      const payload = {
        notes: this.notes,
        contactId: this.selectedContactId,
      }

      const response = await this.$http.post(`/api/companies/saveLead`, payload)
      if (response.data.status==500) {
        this.saveError = true
      } else {
        location.href="leads"
      }
    },

    getCategoryNames(arrCatIds) {
      const categoryOptions = [
        { id: 1, value: "PC/Laptops" },
        { id: 2, value: "Printers" },
        { id: 3, value: "Server/Storage" },
        { id: 4, value: "Networking/Telecom" },
        { id: 5, value: "Software" },
        { id: 6, value: "Electronics" },
        { id: 7, value: "Mobile" },
        { id: 8, value: "POS/BarCode/Banking" },
        { id: 9, value: "Components" },
      ]

      // Map over the array of category IDs
      const categoryNames = arrCatIds.map(id => {
        // Find the category option that matches the current ID
        const category = categoryOptions.find(option => option.id === id)
        // Return the value field of the found category, or an empty string if not found
        return category ? category.value : ''
      });

      // Join the array of names into a comma-separated string
      return categoryNames.join(', ')
    },  
  },
};
</script>

<style>
/* Add any required styling here */
</style>