import Vue from "vue"
import axios from "axios"
import axiosDefaults from "axios/lib/defaults"
import { sync } from "vuex-router-sync"
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate"
import { required } from "vee-validate/dist/rules"
// import ImageUploader from "vue-image-upload-resize"

import App from "./App.vue"
import router from "./router"
import store from "./store"

import "./assets/index.css"

setupAxios()
Vue.config.productionTip = false

// Register Form validation rules with VeeValidate
extend("required", { ...required, message: "This field is required" })
extend("maxChoices", {
  validate: value => {
    const checkedChoices = value.filter(choice => choice).length
    return checkedChoices <= 3
  },
  message: "Max 3 choices allowed",
})

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

// Vue.use(ImageUploader)

// Sync state of router into vuex store
sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")

function setupAxios() {
  // SETUP AXIOS
  // eslint-disable-next-line no-undef
  axiosDefaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3000" : window.location.origin
  axiosDefaults.headers = {
    "Cache-Control": "no-cache",
  }

  // return non-200 codes in then instead of throw
  // See: https://github.com/axios/axios/issues/41
  axiosDefaults.validateStatus = () => true

  // When response contains 'updateUser'-header -> use it's value (stringified usetr object) to update the user
  // User-object may also contain a special object of form:
  axios.interceptors.response.use(
    resp => {
      if (resp.headers["x-updated-user"]) {
        const user = JSON.parse(decodeURI(resp.headers["x-updated-user"]))
        console.log("user", user)
        store.commit("updateUser", user)
      }
      return resp
    },
    error => Promise.reject(error.message)
  )

  Vue.prototype.$http = axios
}
